import React, { useState } from "react"
import Instructions from "../components/instructions"
import Form from "../components/form"
import { useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import PageSpinner from "../components/ui/page_spinner"
import Title from "../components/ui/title"
import Rules from "../components/rules"
import Button from "../components/ui/button"

const carpoolQuery = gql`
  query Carpool($carpoolId: ID!) {
    carpool(id: $carpoolId) {
      name
    }
  }
`

const MemberDetails = () => {
  const { carpoolId, memberId } = useParams()
  const { data: carpoolQueryData, error: carpoolQueryError } = useQuery(
    carpoolQuery,
    { variables: { carpoolId } }
  )
  const [showForm, setShowForm] = useState(false)

  if (carpoolQueryError) return `carpoolError! ${carpoolQueryError}`
  if (!carpoolQueryData) return <PageSpinner />

  return (
    <div className="place-content-center">
      <Title name={carpoolQueryData.carpool.name} />
      {!showForm ? (
        <div>
          <div className="mx-3 sm:mx-40">
            <Rules />
          </div>
          <div className="container py-5 px-5 mx-0 min-w-full flex flex-col items-center">
            <Button
              name="Empezar Formulario"
              type="button"
             
              onClick={() => setShowForm(!showForm)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-center items-center h-full">
            <Button
              name="Volver a ver reglas"
              type="button"
              onClick={() => setShowForm(!showForm)}
            />
          </div>
          <div className="mx-10 my-4 md:mx-40">
            <Instructions />
          </div>
          <div className="mx-3 my-4 sm:mx-12">
            <Form carpoolId={carpoolId} memberId={memberId} />
          </div>
        </div>
      )}
    </div>
  )
}

export default MemberDetails
