import React from "react"

const Input = (props) => {
  const {
    name,
    value,
    placeholder,
    onChange,
    onKeyDown,
    type = "text",
    disabled = false,
    min,
    max,
    className
  } = props
  const minvalue = type === "number" ? min : undefined
  const maxvalue = type === "number" ? max : undefined
  return (
    <div>
      <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
        {name}
      </label>
      <input
        value={value}
        min={minvalue}
        max={maxvalue}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue focus:border-blue block w-full p-2.5 dark:bg-gray dark:border-gray dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`}
      />
    </div>
  )
}

export default Input
