import React, { useEffect, useState } from "react"
import { gql, useQuery, useMutation } from "@apollo/client"
import PageSpinner from "../components/ui/page_spinner"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../components/ui/button"
import MemberTable from "../components/member_table"
import Input from "../components/input"

const membersQuery = gql`
  query Members($carpoolId: ID!) {
    members(carpoolId: $carpoolId) {
      id
      carpoolId
      name
      email
      invitationSentAt
    }
  }
`
const deleteMemberQuery = gql`
  mutation DeleteMember($carpoolId: ID!, $deleteMemberId: ID!) {
    deleteMember(carpoolId: $carpoolId, id: $deleteMemberId)
  }
`

const inviteMemberQuery = gql`
  mutation InviteMember($carpoolId: ID!, $memberId: ID!) {
    inviteMember(carpoolId: $carpoolId, id: $memberId)
  }
`
const createMemberQuery = gql`
  mutation CreateMember($carpoolId: ID!, $email: String!) {
    createMember(carpoolId: $carpoolId, email: $email) {
      email
    }
  }
`

const CarpoolDetails = () => {
  const navigate = useNavigate()
  const [members, setMembers] = useState([])
  const [showNoInvited, setShowNoInvited] = useState(false)
  const [invalidEmails, setInvalidEmails] = useState([])
  const [validEmails, setValidEmails] = useState([])

  const [email, setEmail] = useState("")
  const { carpoolId } = useParams()

  const { data: membersQueryData, error: membersQueryError } = useQuery(
    membersQuery,
    { variables: { carpoolId } }
  )

  const [
    inviteMemberMutation,
    { error: inviteMemberMutationError },
  ] = useMutation(inviteMemberQuery, {
    refetchQueries: ["Members"],
  })

  const [
    deleteMemberMutation,
    { error: deleteMemberMutationError },
  ] = useMutation(deleteMemberQuery, {
    refetchQueries: ["Members"],
  })

  const [
    createMemberMutation,
    { error: createMemberMutationError },
  ] = useMutation(createMemberQuery, {
    refetchQueries: ["Members"],
  })

  const deleteMember = (carpoolId, memberId) => {
    deleteMemberMutation({
      variables: {
        carpoolId,
        deleteMemberId: memberId,
      },
    })
  }

  const createMember = (email) => {
    createMemberMutation({
      variables: {
        carpoolId,
        email,
      },
    })
    setEmail("")
  }

  const inviteMember = (carpoolId, memberId) => {
    inviteMemberMutation({
      variables: {
        carpoolId,
        memberId,
      },
    })
  }

  const editMember = (carpoolId, memberId) => {
    navigate(`/carpools/${carpoolId}/members/${memberId}`)
  }

  useEffect(() => {
    if (deleteMemberMutationError) {
      alert("Error al eliminar miembro")
    }

    if (inviteMemberMutationError) {
      alert("Error al invitar miembro")
    }

    if (createMemberMutationError) {
      alert("Error al crear miembro")
    }
  }, [
    deleteMemberMutationError,
    inviteMemberMutationError,
    createMemberMutationError,
  ])

  const filteredMembers = members.filter((member) => {
    if (showNoInvited && !member.invitationSentAt) {
      return false
    }
    return true
  })

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      createMembersFromEmailList(email)
    }
  }

  const inviteAllMembers = (memberList) => {
    const membersUninvited = memberList.filter((member) => {
      if (showNoInvited && member.invitationSentAt) {
        return false
      }
      return true
    })
    membersUninvited.forEach((member) => {
      inviteMember(member.carpoolId, member.id)
    })
  }

  const createMembersFromEmailList = (emails) => {
    const emailList = emails.split(/\s+/).map((email) => email.trim())
    const validEmailList = []
    const invalidEmailList = []

    emailList.forEach((email) => {
      if (isValidEmail(email)) {
        validEmailList.push(email)
      } else {
        invalidEmailList.push(email)
      }
    })
    validEmailList.forEach((validEmail) => createMember(validEmail))
    setEmail("")
    setInvalidEmails(invalidEmailList)
    setValidEmails(validEmailList)
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  useEffect(() => {
    if (membersQueryData) {
      setMembers(membersQueryData.members)
    }
  }, [membersQueryData])

  if (membersQueryError) {
    return `carpoolError! ${membersQueryError}`
  }

  if (!membersQueryData) {
    return <PageSpinner />
  }

  return (
    <div>
      <div className="container p-4 mx-auto px-4 sm:px-8">
        <h6 className="text-center text-2xl font-bold p-3">Detalle Carpool</h6>
        <div className="grid gap-2 md:grid-cols-2 md:items-end ">
          <div className="w-full">
            <Input
              name="Crea nuevo/s miembro/s "
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Puedes agregar varios Emails separados por espacio"
            />
          </div>

          <div className="w-1/3 justify-self-end  md:justify-self-start  w-auto ">
            <Button
              name="Crear"
              className="bg-green hover:bg-white"
              onClick={() => createMembersFromEmailList(email)}
            />
          </div>
        </div>

        <div className="container mx-auto p-4 flex flex-col items-center justify-center">
          {invalidEmails.length > 0 && invalidEmails[0] !== "" && (
            <div className="text-red font-bold mt-2">
              <h3 className="text-center">Correos electrónicos inválidos:</h3>
              <ul className="list-disc pl-8">
                {invalidEmails.map((invalidEmail, index) => (
                  <li key={index} className="text-red">
                    {invalidEmail}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {validEmails.length > 0 && (
            <div className="text-blue font-bold mt-2">
              <h3 className="text-center">Miembros creados correctamente:</h3>
              <ul className="list-disc pl-8">
                {validEmails.map((validEmail, index) => (
                  <li key={index} className="text-blue">
                    {validEmail}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="container flex p-4 mx-auto px-4 justify-between items-center">
          <div className="flex items-center">
            <input
              className="leading-tight mr-2"
              type="checkbox"
              checked={showNoInvited}
              onChange={(e) => setShowNoInvited(e.target.checked)}
            />
            <label className="block text-gray-700 text-sm font-bold">
              Mostrar solo invitados
            </label>
          </div>
          <div>
            <Button
              name="Invitar a los no invitados"
              className="bg-blue hover:bg-white"
              onClick={() => inviteAllMembers(members)}
            />
          </div>
        </div>
      </div>
      <MemberTable
        members={filteredMembers}
        inviteMember={inviteMember}
        deleteMember={deleteMember}
        editMember={editMember}
      />
    </div>
  )
}
export default CarpoolDetails
