import React, { useState, useEffect } from "react"
import { gql, useQuery, useMutation } from "@apollo/client"
import Button from "../components/ui/button"
import { Member } from "../models/member"
import PageSpinner from "./ui/page_spinner"
import FormTable from "./form_table"
import Input from "./input"

const carpoolQuery = gql`
  query Carpool($carpoolId: ID!) {
    carpool(id: $carpoolId) {
      id
      name
      goSlots
      backSlots
      days
      createdAt
    }
  }
`

const memberQuery = gql`
  query Member($carpoolId: ID!, $memberId: ID!) {
    member(carpoolId: $carpoolId, id: $memberId) {
      id
      carpoolId
      name
      email
      goTrips {
        day
        slot
        canDrive
      }
      backTrips {
        day
        slot
        canDrive
      }
      car {
        plate
        carCapacity
        model
      }
      createdAt
    }
  }
`

const updateMemberMutation = gql`
  mutation Mutation(
    $carpoolId: ID!
    $memberId: ID!
    $memberInput: MemberInput!
  ) {
    updateMember(
      carpoolId: $carpoolId
      id: $memberId
      memberInput: $memberInput
    ) {
      id
      carpoolId
      name
      email
      goTrips {
        day
        slot
        canDrive
      }
      backTrips {
        day
        slot
        canDrive
      }
      car {
        plate
        carCapacity
        model
      }
    }
  }
`

const Form = ({ carpoolId, memberId }) => {
  const { data: carpoolQueryData, error: carpoolQueryError } = useQuery(
    carpoolQuery,
    { variables: { carpoolId } }
  )
  const { data: memberQueryData, error: memberQueryError } = useQuery(
    memberQuery,
    { variables: { carpoolId, memberId } }
  )
  const [
    updateMember,
    { data: updateMemberMutationData, error: updateMemberMutationError },
  ] = useMutation(updateMemberMutation)

  const [goSlots, setGoSlots] = useState([])
  const [backSlots, setBackSlots] = useState([])
  const [member, setMember] = useState(null)

  useEffect(() => {
    if (!carpoolQueryData) return

    setGoSlots(["no voy", ...carpoolQueryData.carpool.goSlots])
    setBackSlots(["no vuelvo", ...carpoolQueryData.carpool.backSlots])
    if (!memberQueryData) return

    const member = new Member(
      memberQueryData.member,
      carpoolQueryData.carpool.days
    )
    setMember(member)
  }, [carpoolQueryData, memberQueryData])

  useEffect(() => {
    if (updateMemberMutationData) {
      alert("Muchas gracias por actualizar tu información!")
      const member = new Member(
        updateMemberMutationData.updateMember,
        carpoolQueryData.carpool.days
      )
      setMember(member)
    } else if (updateMemberMutationError) {
      alert("Ocurrió un error al intentar guardar tu información")
    }
  }, [updateMemberMutationData, updateMemberMutationError, carpoolQueryData])

  if (carpoolQueryError) return `carpoolError! ${carpoolQueryError}`
  if (memberQueryError) return `memberError! ${memberQueryError}`
  if (!member) return <PageSpinner />

  const setPartialMember = (partialMember) =>
    setMember({ ...member, ...partialMember })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateMember({
      variables: {
        memberId: member.id,
        carpoolId: member.carpoolId,
        memberInput: {
          name: member.name,
          car: member.car,
          goTrips: member.goTrips.filter((trip) => trip.slot !== "no voy"),
          backTrips: member.backTrips.filter(
            (trip) => trip.slot !== "no vuelvo"
          ),
        },
      },
    })
  }

  return (
    <div className="p-3 ">
      <form onSubmit={handleSubmit}>
        <div className="grid gap-6 mb-6 sm:grid-cols-2 sm:mx-16">
          <Input
            name="Nombre"
            value={member.name}
            placeholder="Nombre Apellido"
            onChange={(e) => setPartialMember({ name: e.target.value })}
          />
          <Input name="Email" disabled={true} value={member.email} />
        </div>
        <div className="grid gap-6 mb-6 sm:grid-cols-3 sm:mx-16">
          <Input
            name="Patente"
            value={member.car.plate}
            placeholder="AABB00"
            onChange={(e) =>
              setPartialMember({
                car: { ...member.car, plate: e.target.value },
              })
            }
          />
          <Input
            name="Capacidad"
            type="number"
            value={member.car.carCapacity}
            placeholder="5"
            onChange={(e) =>
              setPartialMember({
                car: { ...member.car, carCapacity: Number(e.target.value) },
              })
            }
            min={5}
            max={8}
          />
          <Input
            name="Modelo"
            value={member.car.model}
            placeholder="Toyota Hilux"
            onChange={(e) =>
              setPartialMember({
                car: { ...member.car, model: e.target.value },
              })
            }
          />
        </div>
        <FormTable
          member={member}
          setMember={setMember}
          goSlots={goSlots}
          backSlots={backSlots}
          days={carpoolQueryData.carpool.days}
        />
        <div className="container py-5 px-5 mx-0 min-w-full flex flex-col items-center">
          <Button name="Enviar" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default Form
