import React from "react"

const Button = ({ color, disabled, onClick, className, name }) => {
  const isDisabled = disabled || false

  return (
    <button
      className={`${
        !isDisabled
          ? `inline-block py-2 px-4 text-center `
          : `bg-gray-300 cursor-not-allowed text-gray-500`
      } py-2 px-4 rounded font-semibold border ${className}`}
      onClick={!isDisabled ? onClick : undefined}
    >
      {name}
    </button>
  )
}

export default Button
