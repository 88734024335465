import React from "react"

const Instructions = () => {
  return (
    <div>
      <p className="text-xl mb-3 ">Instrucciones</p>
      <ul className="list-decimal">
        <li className="my-3">
          Escriba solamente un nombre y su primer apellido. Use mayúsculas para
          ambos.
        </li>
        <li className="my-3">
          Recuerde que existen las opciones "no voy" y "no vuelvo".
        </li>
        <li className="my-3">
          Debe haber al menos un día a la semana que pueda manejar ida y vuelta.
        </li>
        <li className="my-3">
          Revise bien los datos antes de enviar el formulario.
        </li>

        <li className="my-3">
          Una vez enviado el formulario se puede editar entrando al mismo link o
          inmediatamante después de haberlo enviado.
        </li>
        <li className="my-3">
          Si no comparte auto no es necesario que coloque su patente y modelo,
          en el caso de tener una capacidad mayor o menor a 5 pasajesros
          especifiquelo en la sección auto.
        </li>
      </ul>
    </div>
  )
}

export default Instructions
