const CheckBox = (prop) => {
  const { value, handleChange, id, text } = prop
  return (
    <div className="mx-auto grid ">
      <label>
        <span className="mr-2">{text}</span>
        <input
          checked={value}
          onChange={() => handleChange(id)}
          id={id}
          type="checkbox"
        />
      </label>
    </div>
  )
}

export default CheckBox
