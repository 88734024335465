import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import LandingPage from "./pages/landing_page"
import NotFoundPage from "./pages/general/error"
import CarpoolDetails from "./pages/carpool_details"
import MemberDetails from "./pages/member_details"
import Home from "./pages/home"

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/carpools/:carpoolId/members/:memberId",
          element: <MemberDetails />,
        },
        {
          path: "/carpools/:carpoolId",
          element: <CarpoolDetails />,
        },
        {
          path: "not-found",
          element: <NotFoundPage />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default App
