export class Member {
  id = ""
  carpoolId = ""
  name = ""
  email = ""
  goTrips = []
  backTrips = []
  car = {}

  constructor(member, days) {
    const newMember = JSON.parse(JSON.stringify(member))
    this.id = newMember.id
    this.carpoolId = newMember.carpoolId
    this.name = newMember.name
    this.email = newMember.email
    this.goTrips = newMember.goTrips.map(this.deleteTypeName)
    this.backTrips = newMember.backTrips.map(this.deleteTypeName)
    this.car = this.deleteTypeName(newMember.car || {})

    this.addMissingTrips(days, this.goTrips, "no voy")
    this.addMissingTrips(days, this.backTrips, "no vuelvo")
  }

  addMissingTrips(days, trips, defaultSlot) {
    for (const day of days) {
      if (trips.find((trip) => trip.day === day)) continue
      trips.push({ day, slot: defaultSlot, canDrive: false })
    }
  }

  deleteTypeName(obj) {
    delete obj["__typename"]
    return obj
  }
}
