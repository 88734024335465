import Button from "./ui/button"
import Modal from "../components/modal"
import { useState } from "react"

const MemberTable = (props) => {
  const { members, editMember, inviteMember, deleteMember } = props
  const [showModal, setShowModal] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState(null)
  const [memberToInvite, setMemberToInvite] = useState(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleInviteAgain = (memberId) => {
    const memberToBeInvited = members.find((member) => member.id === memberId)

    if (memberToBeInvited.invitationSentAt) {
      setMemberToInvite(memberToBeInvited)
      setIsConfirmationModalOpen(true)
    } else {
      inviteMember(memberToBeInvited.carpoolId, memberId)
    }
  }

  const handleConfirmInvitation = () => {
    if (memberToInvite) {
      inviteMember(memberToInvite.carpoolId, memberToInvite.id)
    }
    setIsConfirmationModalOpen(false)
    setMemberToInvite(null)
  }

  const handleCancelInvitation = () => {
    setIsConfirmationModalOpen(false)
    setMemberToInvite(null)
  }

  const openModal = (member) => {
    setMemberToDelete(member)
    setShowModal(true)
    console.log(member.email)
  }

  const closeModal = () => {
    setMemberToDelete(null)
    setShowModal(false)
  }

  const handleConfirmDelete = () => {
    if (memberToDelete) {
      deleteMember(memberToDelete.carpoolId, memberToDelete.id)
    }
    closeModal()
  }

  return (
    <div className="antialiased font-sans bg-gray-200">
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-2">
          <div>
            <h1 className="font-semibold leading-tight">Miembros</h1>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        <div className="flex-col items-center ">
                          <p className="text-gray-900 whitespace-pre-wrap block mb-2 break-all">
                            {member.email}
                          </p>
                        </div>
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-pre-wrap block mb-2">
                          {member.name || "---"}
                        </p>
                      </td>

                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        {member.invitationSentAt ? (
                          <p className="text-green whitespace-no-wrap">
                            INVITADO
                          </p>
                        ) : (
                          <p className="text-red whitespace-no-wrap">
                            NO INVITADO
                          </p>
                        )}
                      </td>
                      <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                        <div className="flex flex-col gap-1 items-center md:flex-row md:gap-2 md:items-center">
                          <Button
                            name="Editar"
                            className="bg-blue"
                            onClick={() =>
                              editMember(member.carpoolId, member.id)
                            }
                          />
                          <Button
                            name="Invitar"
                            className="bg-green"
                            onClick={() => handleInviteAgain(member.id)}
                          />
                          <Button
                            name="Eliminar"
                            className="bg-red"
                            onClick={() => openModal(member)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={closeModal}
        onConfirm={handleConfirmDelete}
        title={`¿Seguro que quieres eliminar a ${
          memberToDelete && memberToDelete.name
            ? memberToDelete.name
            : memberToDelete
            ? memberToDelete.email
            : "este miembro"
        }?`}
      />
      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={handleCancelInvitation}
        onConfirm={handleConfirmInvitation}
        title={`¿Seguro que quieres invitar de nuevo a ${
          memberToInvite && memberToInvite.name
            ? memberToInvite.name
            : memberToInvite
            ? memberToInvite.email
            : "este miembro"
        }?`}
      />
    </div>
  )
}
export default MemberTable
