import React from "react"

const DropDown = (props) => {
  const { slots, id, handleChange, value } = props

  return (
    <div className="px-9">
      <select
        onChange={handleChange}
        key={id}
        defaultValue={value}
        className="bg-white text-center border border-gray-300 text-gray-900 rounded-lg focus:ring-brown focus:border-brown block w-full "
      >
        {slots.map((slot) => (
          <option key={slot} value={slot} id={id}>
            {slot}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DropDown
