import React from "react"

const Rules = () => {
  return (
    <div className="text-justify">
      <p className="text-xl mb-3 ">Reglas Turno</p>

      <ul className="list-decimal">
        <li className="my-3">
          Todas las personas que estén en el turno deben manejar un día. No se
          aceptará que entren personas sin manejar y ofreciendo plata a cambio.
        </li>
        <li className="my-3">
          Cada persona maneja oficialmente un solo día a la semana. Ese día debe
          hacerse responsable, tanto a la ida como a la vuelta, de las personas
          que lleva. Si no pudiera hacer su turno en los horarios establecidos,
          entonces deberá conseguirles el traslado con otro o dejarles el auto.
        </li>
        <li className="my-3">
          La persona que maneja no es responsable de estarle preguntando a
          quienes lleva si van o vuelven, cada persona tiene que avisarle al que
          lo traslada si no se va/vuelve con él.
        </li>
        <li className="my-3">
          Estar en el turno es un compromiso. Una vez hecho el turno, nadie
          podrá salirse a mitad de semestre, a menos que se encargue de hacer
          todos los arreglos pertinentes para que el turno siga funcionando sin
          problemas.
        </li>

        <li className="my-3">
          Se hace lo posible para que los que manejen vivan cerca de aquellos
          que llevan, pero no siempre se puede. Si van a volver con alguien que
          vive lejos de ustedes traten de conseguirse cómo volver a su casa en
          ese último tramo (dejar su auto en alguna casa, pedir que los vayan a
          buscar o moverse con otros que están en las mismas). Por la otra
          parte, los que manejan pueden ayudar de vez en cuando y llevar a su
          casa a alguien que no tenga como hacerlo. Hay que tener criterio para
          ambos lados, porque hay horas en que el taco en Calera es terrible.
          Consideren esto cuando quieran volverse con otro que no es su turno
          oficial.
        </li>

        <li className="my-3">
          Ser respetuosos con los horarios. Aunque a muchos no les importe
          llegar a la hora, hay que ser puntuales porque a los otros puede si
          importarles (o tienen controles o asistencia). Lo mismo con las
          salidas, hay que evitar quedarse conversando o ponerse a hacer otros
          trámites porque los demás están esperando.
        </li>
      </ul>
    </div>
  )
}

export default Rules
