import React from "react"
import Title from "../components/ui/title"
import { Link } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

const carpoolsQuery = gql`
  query Carpools {
    carpools {
      id
      name
      createdAt
  }
  }
`

const Home = () => {
  const { data: carpoolsData, error, loading } = useQuery(carpoolsQuery)

  if (loading) return null
  if (error) return 'Error'

  const carpools = carpoolsData.carpools
  return (
    <div className="mt-4 place-content-center">
      <Title name="Home" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {(!carpools.length) && "No carpools"}
          {carpools.map((carpool) => (
            <Link to={`/carpools/${carpool.id}`} key={carpool.id}>
              <div key={carpool.id} className="bg-white p-4 rounded shadow">
                <h3 className="text-xl font-semibold">{carpool.name}</h3>
                <p className="text-gray-600">{carpool.createdAt}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Home
