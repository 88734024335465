import React, { useState } from "react"
import Button from "../components/ui/button"
import Title from "../components/ui/title"
import Input from "../components/input"
import { useNavigate } from "react-router-dom"
import { gql, useMutation } from "@apollo/client"


const SIGN_UP_MUTATION = gql`
  mutation SignUp($email: String!) {
    signUp(email: $email)
  }
`

const LOG_IN_MUTATION = gql`
  mutation LogIn($email: String!, $pin: String!) {
    logIn(email: $email, pin: $pin) {
      success
      accessToken
    }
  }
`

const LandingPage = () => {
  const appName = "Kahrpool App"
  const navigate = useNavigate()
  const [gqlSignUp, { loading: signUpLoading }] = useMutation(SIGN_UP_MUTATION)
  const [gqlLogIn, { loading: logInLoading }] = useMutation(LOG_IN_MUTATION)
  const [showPinInput, setShowPinInput] = useState(false)

  const logIn = async (e) => {
    e.preventDefault()
    const email = e.target[0].value 
    const pin = e.target[1].value 

    if (!email) {
      alert("Por favor ingrese un correo para iniciar sesión")
    }
    else if (email && !showPinInput && !signUpLoading) {
      try {
        await gqlSignUp({ variables: { email } })
        setShowPinInput(true)
      } catch (e) {
        alert("Error al intentar iniciar sesión. Asegúrese que el correo ingresado es válido")
      }
    } else if (email && pin && !logInLoading) {
      try {
        const data = await gqlLogIn({ variables: { email, pin } })
        localStorage.setItem("accessToken", data.data.logIn.accessToken)
        navigate('/home')
      } catch (error) {
        alert("El correo y PIN ingresados no son válidos")
        e.target[1].value = ""
      }
    }
  }

  return (
    <div className="p-4 mt-4 flex flex-col items-center justify-center">
      <Title name={appName} />
      <form className="container px-5 m-20 mx-0 flex gap-2 flex-col items-start" onSubmit={logIn}>
        <div className="w-full md:w-1/2">
          <Input name="Email" type="email" disabled={showPinInput}/>
        </div>
        <div className={`w-full md:w-1/2 transition-transform ${showPinInput ? "" : "translate-x-[-500%]"}`}>
          <Input name="PIN"/>
        </div>
        <div className="w-1/3">
          <Button name="Login" type="submit"/>
        </div>
      </form>
    </div>
  );
}

export default LandingPage
