import React from "react"

function Row() {
  return (
    <tr className="sm:hidden h-54px  bg-gray flex flex-col flex-no wrap sm:table-row mb-2">
      <th className="border text-center py-3 px-4  font-semibold">-</th>
      <th className="border text-center py-3 px-4  font-semibold">
        Horario Entrada
      </th>
      <th className="border text-center py-3 px-4  font-semibold">
        Horario Salida
      </th>
      <th className="border text-center py-3 px-4  font-semibold">
        Puede Manejar
      </th>
    </tr>
  )
}

export default Row
