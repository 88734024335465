import React from "react"
import Row from "./ui/row"
import DropDown from "./ui/drop_down"
import CheckBox from "./ui/check_box"

const FormTable = (props) => {
  const { member, setMember, goSlots, backSlots, days } = props

  const dayLength = days.length

  const initialCheckBox = (day, go) => {
    const newMember = JSON.parse(JSON.stringify(member))

    const tripArray = go ? newMember.goTrips : newMember.backTrips
    const trip = tripArray.find((trip) => trip.day === day)
    return trip.canDrive
  }

  const initialDropDown = (day, go) => {
    const newMember = JSON.parse(JSON.stringify(member))

    const tripArray = go ? newMember.goTrips : newMember.backTrips
    const trip = tripArray.find((trip) => trip.day === day)
    return trip.slot
  }

  const handleChangeDropDown = (event) => {
    event.preventDefault()

    const index = event.target.selectedIndex
    const optionElement = event.target.childNodes[index]
    const optionElementId = optionElement.getAttribute("id")

    const newMember = JSON.parse(JSON.stringify(member))

    const [direction, day] = optionElementId.split("_")
    const tripArray =
      direction === "G" ? newMember.goTrips : newMember.backTrips
    const trip = tripArray.find((trip) => trip.day === day)
    trip.slot = event.target.value
    trip.canDrive = !["no voy", "no vuelvo"].includes(trip.slot)

    setMember(newMember)
  }

  const handleChangeCheckBox = (data) => {
    const newMember = JSON.parse(JSON.stringify(member))

    const [direction, day] = data.split("_")
    const tripArray =
      direction === "G" ? newMember.goTrips : newMember.backTrips
    const trip = tripArray.find((trip) => trip.day === day)
    trip.canDrive = !trip.canDrive
    setMember(newMember)
  }

  const rows = []

  for (let i = 1; i < dayLength; i++) {
    rows.push(<Row />)
  }

  return (
    <div className="flex items-center justify-center">
      <div className="container">
        <table className="sm:inline-table w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden sm:shadow-lg my-5">
          <thead className="bg-white">
            <tr className="bg-gray flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-2">
              <th className="border text-center py-3 px-4  font-semibold">-</th>
              <th className="border text-center py-3 px-4  font-semibold">
                Horario Entrada
              </th>
              <th className="border text-center py-3 px-4  font-semibold">
                Horario Salida
              </th>
              <th className="border text-center py-3 px-4  font-semibold">
                Puede Manejar
              </th>
            </tr>
            {rows.map((row, index) => (
              <tr key={index}>{row}</tr>
            ))}
          </thead>

          <tbody className="flex-1 sm:flex-none ">
            {days.map((day) => (
              <tr className="h-50px flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-2">
                <td className="h-50px border text-center py-3 px-1 font-semibold bg-blue">
                  {day}
                </td>
                <td className="border text-center py-3 px-1">
                  <DropDown
                    slots={goSlots}
                    value={initialDropDown(day, true)}
                    id={"G_" + day + "_SLOT"}
                    handleChange={handleChangeDropDown}
                  />
                </td>
                <td className="border text-center py-3 px-1">
                  <DropDown
                    slots={backSlots}
                    value={initialDropDown(day, false)}
                    id={"B_" + day + "_SLOT"}
                    handleChange={handleChangeDropDown}
                  />
                </td>
                <td className=" flex border text-center py-3 px-1">
                  <CheckBox
                    value={initialCheckBox(day, true)}
                    id={"G_" + day}
                    text="ida"
                    handleChange={handleChangeCheckBox}
                  />
                  <CheckBox
                    value={initialCheckBox(day, false)}
                    id={"B_" + day}
                    text="vuelta"
                    handleChange={handleChangeCheckBox}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FormTable
